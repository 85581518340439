<template>
    <LiefengContent>
        <template v-slot:title>
            外籍人管理
            <!-- <Button type="error" v-if="$route.query.name && !$route.query.isLineHome" @click="$router.push(`/${$route.query.name}`)">返回</Button>
      <Button type="error" v-if="$route.query.name && $route.query.isLineHome && $route.query.page" @click="$router.push(`/${$route.query.name}?isLineHome=true&page=true`)">返回</Button> -->
            <span class="eye" @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
        </template>
        <template v-slot:toolsbarLeft></template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Cascader
                        transfer
                        @on-change="changeCasder"
                        :load-data="loadData"
                        change-on-select
                        style="margin-right: 10px; width: 300px"
                        :data="CascaderList"
                        v-model="CascaderId"
                        placeholder="请选择区/街道/社区"
                    ></Cascader>
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" placeholder="请输入姓名" v-model.trim="selectObj.userName" style="width: 200px; margin-left: 10px" @on-search="search"></Input>
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" placeholder="请输入手机号" v-model.trim="selectObj.mobile" style="width: 200px; margin-left: 10px" @on-search="search"></Input>
                </FormItem>
                <FormItem>
                    <Select clearable transfer placeholder="请选择人员类型" v-model="selectObj.userType" style="width: 200px; margin-left: 10px">
                        <Option v-for="(item, index) in userType" :key="index" :value="item.dictKey">{{ item.dictValue }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select clearable transfer placeholder="请选择状态" v-model="selectObj.liveStatus" style="width: 200px; margin-left: 10px">
                        <Option value="1">居住中</Option>
                        <Option value="2">已搬离</Option>
                    </Select>
                </FormItem>
                <Button type="primary" icon="ios-search-outline" style="margin-right: 10px" @click="search">搜索</Button>
                <Button type="success" icon="ios-refresh" style="margin-right: 10px" @click="reset">重置</Button>
                <Button type="primary" style="margin-right: 10px" @click="importExcel">导入</Button>
                <Button @click="exportExcel" type="error" icon="ios-cloud-download-outline">导出</Button>
                <!-- </template> -->
                <!-- <Button type="primary" style="margin-right: 10px" @click="add">新增</Button> -->
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="tableColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
                @tableSelect="tableSelect"
            ></LiefengTable>

            <!-- 导入excel弹窗 -->
            <LiefengModal :title="editType == 'add' ? '新增' : '修改'" width="600px" height="70vh" :value="editStatus" @input="changeEditStatus">
                <template v-slot:contentarea>
                    <Editalien :id="id" ref="editalien" :editRow="editRow"></Editalien>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin: 0 5px 0 13px" type="info" @click="editStatus = false">取消</Button>
                    <Button style="margin: 0 5px 0 13px" type="primary" :loading="saveLoading" @click="editSave">保存</Button>
                </template>
            </LiefengModal>

            <!-- 导入 -->
            <LiefengModal title="导入" width="40%" height="260px" :value="excelStatus" @input="excelModalData" class="export-modal">
                <template v-slot:contentarea v-if="modalStatus">
                    <Form :label-width="100">
                        <div style="display: flex">
                            <span style="padding-top: 5px; margin-right: 5px" class="validate">选择社区</span>
                            <LiefengCascaderCity :resetNum="resetNum" :CascaderList="orgCodeList" :orgCode="4401" @changeCasader="changeExcelCasader"></LiefengCascaderCity>
                        </div>
                    </Form>
                    <LiefengUploadExcel style="margin: 20px 0" :headers="headers" :action="exportAction" :uploadData="uploadData" :isShow="showExcel" @success="excelsuccess"></LiefengUploadExcel>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/verified')
import LiefengTable from "@/components/LiefengTable"
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import Editalien from "./children/editaline"
import LiefengUploadExcel from "./children/LiefengUploadExcel"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"

export default {
    components: {
        LiefengTable,
        LiefengContent,
        LiefengModal,
        Editalien,
        LiefengUploadExcel,
        LiefengCascaderCity,
    },
    data() {
        return {
            //脱敏
            hasSensitive: true,
            //搜索
            selectObj: {
                userName: "",
                mobile: "",
                userType: "",
                liveStatus: "",
            },

            //table列表
            tableColumns: [
                {
                    title: "社区",
                    key: "authOrgName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "userName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "人员类型",
                    key: "userType",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            this.userType.map(item => {
                                if (params.row.userType == item.dictKey) return item.dictValue
                            })
                        )
                    },
                },
                {
                    title: "证件类型",
                    key: "idNumType",
                    align: "center",
                    minWidth: 130,
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            this.idNumType.map(item => {
                                if (params.row.idNumType == item.dictKey) return item.dictValue
                            })
                        )
                    },
                },
                {
                    title: "证件号码",
                    key: "idNum",
                    align: "center",
                    minWidth: 130,
                },
                {
                    title: "联系电话",
                    key: "mobile",
                    align: "center",
                    minWidth: 130,
                },
                {
                    title: "性别",
                    key: "sex",
                    align: "center",
                    minWidth: 80,
                    render: (h, params) => {
                        return h("div", {}, params.row.sex == 1 ? "男" : params.row.sex == 2 ? "女" : params.row.sex)
                    },
                },
                {
                    title: "出生日期",
                    key: "idType",
                    minWidth: 130,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [h("span", {}, params.row.birthday ? this.$core.formatDate(new Date(params.row.birthday), "yyyy-MM-dd") : "")])
                    },
                },
                {
                    title: "国籍",
                    key: "nationality",
                    minWidth: 170,
                    align: "center",
                },
                {
                    title: "居住时长",
                    key: "liveType",
                    minWidth: 170,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            this.liveType.map(item => {
                                if (params.row.liveType == item.dictKey) return item.dictValue
                            })
                        )
                    },
                },
                {
                    title: "现住地址",
                    key: "address",
                    minWidth: 170,
                    align: "center",
                },
                {
                    title: "提交时间",
                    key: "birthday",
                    minWidth: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [h("span", {}, params.row.gmtModified ? this.$core.formatDate(new Date(params.row.gmtModified), "yyyy-MM-dd hh:mm:ss") : "")])
                    },
                },
                {
                    title: "状态",
                    key: "liveStatus",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: params.row.liveStatus == 2 ? "red" : "#000",
                                },
                            },
                            params.row.liveStatus == 2 ? "已搬离" : "居住中"
                        )
                    },
                },

                {
                    title: "操作",
                    key: "action",
                    fixed: "right",
                    align: "center",
                    minWidth: 250,
                    render: (h, params) => {
                        let data = params.row
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: {
                                        marginRight: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            this.delete(params.row.authId)
                                        },
                                    },
                                },
                                "删除"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        marginRight: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            if(params.row.custGlobalId && params.row.custGlobalId != ''){
                                                 this.getDetail(params.row.custGlobalId)
                                            }else{  
                                                 this.getRerowDetail(params.row.authId)
                                            }
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        // display: params.row.status === "1" ? "none" : "inline-block",
                                    },
                                    on: {
                                        click: () => {
                                            this.changeStatus(params.row)
                                        },
                                    },
                                },
                                params.row.liveStatus == 2 ? "搬回" : "搬离"
                            ),
                        ])
                    },
                },
            ],
            tableData: [], //table的数据
            loading: false, //table 加载
            page: 1, //分页
            total: 0, //总数量
            pageSize: 20, //每一页显示的数量

            editType: "",
            editStatus: false,

            //人员类型
            userType: [],
            // 证件类型
            idNumType: [],
            idNumTypeTow: [],
            //居住时长
            liveType: [],

            CascaderList: [],
            CascaderId: [],
            isNum: 0,

            editRow: {
                orgCode: "",
                orgName: "",
            },
            saveLoading: false,
            id: "",

            headers: {
                Authorization: window.sessionStorage.getItem("accessToken"),
            },
            exportAction: window.vue.getProxy()["/gateway"].target + "/api/lfuser/userAuth/importUserAuth",

            uploadData: {
                orgCode: "",
                oemCode: parent.vue.oemInfo.oemCode,
            },

            showExcel: 0,

            excelStatus: false,

            resetNum: 0,

            orgCodeList: [],

            modalStatus:false
        }
    },
    async created() {
        this.getCascader()
        this.getTypeList("USER_TYPE", "userType")
        this.getTypeList("ID_NUM_TYPE", "idNumType", "idNumTypeTow")
        this.getTypeList("LIVE_TYPE", "liveType")
    },
    beforeMount() {
        this.tableHeight = window.innerHeight - 200 + ""
    },
    methods: {
        changeExcelCasader(val) {
            console.log(val);
            if (val && val.length == 3) {
                this.uploadData.orgCode = val[val.length - 1]
            } else {
                this.uploadData.orgCode = ""
            }
        },
        excelsuccess() {
            this.excelStatus = false
            this.page = 1
            this.getList()
        },
        // 关闭导入弹窗事件
        excelModalData(status) {
            this.excelStatus = status
            if(!status) this.modalStatus = false
        },
        importExcel() {
            this.excelStatus = true
            this.showExcel++
            this.resetNum = 0
            this.orgCodeList = []
            this.uploadData.orgCode = ""
            this.modalStatus = true
        },
        // 导出
        async exportExcel() {
            await this.$core.exportGetExcel(
                "/gateway/api/lfuser/userAuth/exportUserAuth",
                {
                    authOrgCode: this.CascaderId[this.CascaderId.length - 1],
                    ...this.selectObj,
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    oemCode: parent.vue.oemInfo.oemCode,
                },
                this,
                "post"
            )
        },
        // 获取级联
        getCascader() {
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: "4401",
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    this.CascaderList = this.forMatterTree(res.dataList)
                    this.CascaderList.map(item => {
                        item.loading = false
                    })
                    if (this.isNum == 0 && this.CascaderList.length != 0) {
                        this.CascaderId.push(this.CascaderList[0].value)
                    }
                    // this.hadlePageSize({
                    //     pageSize: this.pageSize,
                    //     page: this.pageNum,
                    // })
                }
            })
        },
        changeCasder(val, item) {
            if (item.length == 1) {
                // this.$Message.warning({
                //   content:'请选择到社区进行查询',
                //   background:true
                // })
                // return
            } else if (item.length == 2) {
                //  this.$Message.warning({
                //     content:'请选择到社区进行查询',
                //     background:true
                //   })
                //   return
            } else if (item.length == 3) {
                this.orgCode = item[2].orgCode
                this.orgName = item[2].orgName
                console.log(this.orgName)
            }
        },
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName
                data[i].value = data[i].orgCode
                if (data[i].children && data[i].children != null && data[i].children.length > 0) {
                    this.forMatterTree(data[i].children)
                }
            }
            return data
        },
        loadData(item, callback) {
            item.loading = true
            let params = {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: item.orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                ...params,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    let arr = this.forMatterTree(res.dataList)
                    arr.map(items => {
                        if (items.orgLevel == 4) {
                            items.loading = false
                        }
                    })
                    item.loading = false
                    item.children = arr
                    callback(item.children)
                    if (this.isNum == 0 && item.children.length != 0) {
                        this.CascaderId.push(item.children[0].value)
                        this.isNum = 1
                        return
                    } else if (this.isNum == 1 && item.children.length != 0) {
                        this.CascaderId.push(item.children[0].value)
                        this.isNum++
                        this.orgCode = item.children[0].value
                        // console.log(item.children[0])
                        console.log(item.children[0].orgName)

                        this.orgName = item.children[0].orgName
                        this.orogCode = item.children[0].value
                        console.log(this.editRow)
                        this.getList()
                    }
                } else {
                    callback([])
                }
            })
        },
        // 字典
        getTypeList(code, list, listtow) {
            this.$get("/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: code,
                appCode: "",
            }).then(res => {
                this[list] = res.dataList
                this.$refs.editalien[list] = res.dataList
                if (listtow) {
                    this[listtow] = res.dataList
                    this.$refs.editalien[listtow] = res.dataList
                }
            })
        },
        //分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 脱敏
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.getList()
        },
        //搜索
        search() {
            this.isNum++
            if (this.CascaderId.length != 3) {
                this.$Message.warning({
                    content: "请选择到社区再进行查询",
                    background: true,
                })
                return
            }
            this.editRow.authOrgCode = this.orgCode
            this.editRow.orgName = this.orgName
            this.page = 1
            this.getList()
            console.log(this.editRow)
        },
        //重置
        reset() {
            this.selectObj = {}
            this.page = 1
            this.getList()
        },
        // 获取列表
        getList() {
            this.loading = true
            this.$get("/gateway/api/lfuser/userAuth/queryUserAuth", {
                hasSensitive: this.hasSensitive,
                authOrgCode: this.CascaderId[this.CascaderId.length - 1],
                ...this.selectObj,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                page: this.page,
                pageSize: this.pageSize,
            })
                .then(res => {
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.tableData = res.dataList
                        this.total = res.maxCount
                        this.page = res.currentPage
                    } else {
                        this.$Message.error({
                            content: "获取数据失败",
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                })
        },
        // 编辑框状态
        changeEditStatus(val) {
            this.editStatus = val
        },
        add() {
            this.editType = "add"
            this.editStatus = true
            this.editRow = {
                orgName: this.orgName,
                authOrgCode: this.orgCode,
                userType: "2",
                idNumType: "1",
                liveType: "1",
                sex: "1",
                nationality: "中国",
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                authType: "",
            }

            console.log(this.editRow)
        },
        // 编辑保存按钮
        editSave() {
            if (this.editRow.authOrgCode == "") {
                this.$Message.warning({
                    content: "请选择社区",
                    background: true,
                })
                return
            }
            if (!this.editRow.userName) {
                this.$Message.warning({
                    content: "姓名不能为空",
                    background: true,
                })
                return
            }
            if (!this.editRow.userType) {
                this.$Message.warning({
                    content: "人员类型不能为空",
                    background: true,
                })
                return
            }
            if (!this.editRow.idNumType) {
                this.$Message.warning({
                    content: "证件类型不能为空",
                    background: true,
                })
                return
            }
            if (!this.editRow.idNum) {
                this.$Message.warning({
                    content: "证件号码不能为空",
                    background: true,
                })
                return
            }
            if (!this.editRow.mobile) {
                this.$Message.warning({
                    content: "手机号码不能为空",
                    background: true,
                })
                return
            }
            if (!this.editRow.sex) {
                this.$Message.warning({
                    content: "性别不能为空",
                    background: true,
                })
                return
            }
            if (!this.editRow.birthday) {
                this.$Message.warning({
                    content: "出生日期不能为空",
                    background: true,
                })
                return
            }
            if (!this.editRow.nationality) {
                this.$Message.warning({
                    content: "国籍不能为空",
                    background: true,
                })
                return
            }
            if (!this.editRow.liveType) {
                this.$Message.warning({
                    content: "居住时长不能为空",
                    background: true,
                })
                return
            }
            if (!this.editRow.address) {
                this.$Message.warning({
                    content: "现住地址不能为空",
                    background: true,
                })
                return
            }
            console.log(this.editRow)

            let url = ""
            if (this.editType == "add") url = "/gateway/api/lfuser/userAuth/addUserAuth"
            else if (this.editType == "edit") url = "/gateway/api/lfuser/userAuth/updateUserAuth"

            this.saveLoading = true
            this.$post(
                url,
                {
                    ...this.editRow,
                },
                { "content-type": "application/json" }
            )
                .then(res => {
                    this.saveLoading = false
                    if (res.code == 200) {
                        this.$Message.success({
                            content: this.editType == "add" ? "新增成功" : "修改成功",
                            background: true,
                        })
                        this.editStatus = false
                        this.getList()
                    } else {
                        this.$Message.error({
                            content: this.editType == "add" ? "新增失败" : "修改失败",
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    this.saveLoading = false
                    conosle.log(err)
                    this.$Message.error({
                        content: this.editType == "add" ? "新增失败" : "修改失败",
                        background: true,
                    })
                })
        },

        // 删除
        delete(authId) {
            this.$Modal.confirm({
                title: "删除确定",
                content: "是否删除当前数据",
                onOk: () => {
                    this.$post("/gateway/api/lfuser/userAuth/deleteUserAuth", {
                        authId,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    content: "删除成功",
                                    background: true,
                                })
                                this.getList()
                            } else {
                                this.$Message.error({
                                    content: "删除失败",
                                    background: true,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                content: "删除失败",
                                background: true,
                            })
                        })
                },
            })
        },
        // 修改
        getDetail(custGlobalId) {
            this.id = custGlobalId
            this.$get("/gateway/api/lfuser/userAuth/selectUserAuth", {
                custGlobalId,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.editType = "edit"
                        this.editStatus = true
                        this.editRow = res.data
                        this.editRow.birthday = res.data && res.data.birthday ? this.$core.formatDate(new Date(res.data.birthday), "yyyy-MM-dd") : ""
                    } else {
                        this.$Message.error({
                            content: "获取详情失败",
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        content: "获取详情失败",
                        background: true,
                    })
                })
        },
        // 根据id获取信息
        getRerowDetail(authId) {
            this.$get("/gateway/api/lfuser/userAuth/selectUserAuthById", {
                authId:authId,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.editType = "edit"
                        this.editStatus = true
                        this.editRow = res.data
                        this.editRow.birthday = res.data && res.data.birthday ? this.$core.formatDate(new Date(res.data.birthday), "yyyy-MM-dd") : ""
                    } else {
                        this.$Message.error({
                            content: "获取详情失败",
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        content: "获取详情失败",
                        background: true,
                    })
                })
        },
        // 搬离/回
        changeStatus(data) {
            this.$post("/gateway/api/lfuser/userAuth/updateLiveStatus", {
                status: data.liveStatus == 2 ? "1" : "2",
                custGlobalId: data.custGlobalId,
                id:data.authId
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "操作成功",
                            background: true,
                        })
                        this.getList()
                    } else {
                        this.$Message.error({
                            content: "操作失败",
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        content: "操作失败",
                        background: true,
                    })
                })
        },
    },
}
</script>

<style scoped lang='less'>
// /deep/ #toolsbarRight {
//   width: 1000%;

// }

.header-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.edit {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        padding-bottom: 20px;
    }
}
.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    margin: 0 15px 0;
}
</style>


